<template>
    
    <container-app>
        <template #content>

            <!-- filter semester -->
            <div class="d-flex align-items-center gap-2 mb-5 ms-5">
                <label class="form-label m-0">Semester</label>
                <select class="form-select w-auto" v-model="jadwal_id" @change="reviewerPengusulan">
                    <option 
                        v-for="jadwal in data_jadwal_pengusulan" 
                        :key="jadwal.id" 
                        :value="jadwal.id">
                        {{jadwal.semester}}
                    </option>
                </select>
            </div>

            <card-basic>
                <template #content>

                    <table-basic>
                        <template #thead>
                            <tr class="fw-bolder bg-light fs-7">
                                <th class="rounded-start ps-5 w-md-50px">No.</th>
                                <th class="w-md-200px">Dosen</th>
                                <th>Usulan PPM</th>
                                <th class="rounded-end pe-5 w-md-150px">Aksi</th>
                            </tr>
                        </template>

                        <template #tbody>
                            <tr class="fs-7" v-for="(pengusul, i) in data_pengusul" :key="i">
                                <td class="text-end ps-5">{{ i+1 }}</td>
                                <td>
                                    <span class="fw-bold d-block">{{ pengusul.nama }}</span>
                                    NIND. {{ pengusul.nidn }}
                                </td>
                                <td>
                                    <div v-for="(data, i) in pengusul.data_usulan" :key="i" :class="i > 0 ? 'mt-6' : ''">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <span>Usulan {{ i+1 }}</span>
                                                <span class="badge badge-info ms-3">{{ data.jenis }}</span>
                                            </div>
                                            <span class="badge badge-danger" v-if="data.kunci == 0">Unreviewed</span>
                                            <span class="badge badge-success" v-else>Reviewed</span>
                                        </div>
                                        <p class="fw-bold mb-1">{{ data.judul }}</p>
                                    </div>
                                </td>
                                <td class="pe-5">
                                    <button class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#detailUsulan" @click="detailUsulan(i)">Detail</button>
                                </td>
                            </tr>
                        </template>
                    </table-basic>

                </template>
            </card-basic>

        </template>
    </container-app>

    <!-- begin::modal -->
    <!-- begin::detailPenelitian -->
    <modal-medium id="detailPenelitian" title="Detail Penelitian">
        <template #content>

            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link active" id="nav-detail_penelitian-tab" data-bs-toggle="tab" data-bs-target="#nav-detail_penelitian" type="button" role="tab" aria-controls="nav-detail_penelitian" aria-selected="true">Penelitian</button>
                    <button class="nav-link" id="nav-detail_anggota-tab" data-bs-toggle="tab" data-bs-target="#nav-detail_anggota" type="button" role="tab" aria-controls="nav-detail_anggota" aria-selected="true">Anggota</button>
                    <button class="nav-link" id="nav-detail_reviewer-tab" data-bs-toggle="tab" data-bs-target="#nav-detail_reviewer" type="button" role="tab" aria-controls="nav-detail_reviewer" aria-selected="true">Reviewer</button>
                </div>
            </nav>

            <div class="tab-content p-1 bg-white border border-secondary border-top-0" id="nav-tabContent">
                <div class="tab-pane fade show active p-4" id="nav-detail_penelitian" role="tabpanel" aria-labelledby="nav-penelitian-tab">
                    <div class="row mb-3">
                        <div class="col-sm-2">Jenis</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.jenis }}</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">Tema</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.tema }}</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">Judul</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.judul }}</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">Latar Belakang</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.latar_belakang }}</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">Tujuan</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.tujuan }}</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">Metode</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.metode }}</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-2">Kebaruan</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusul.kebaruan }}</span>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="nav-detail_anggota" role="tabpanel" aria-labelledby="nav-anggota-tab">

                    <table-basic>
                        <template #thead>
                            <tr class="fw-bolder bg-light fs-7">
                                <th class="rounded-start ps-5 w-md-50px">No.</th>
                                <th>Anggota</th>
                            </tr>
                        </template>

                        <template #tbody>
                            <tr v-for="(anggota, i) in data_anggota" :key="i">
                                <th class="ps-5 text-end">{{ i+1 }}</th>
                                <th>{{ anggota.anggota_id }} - {{ anggota.anggota_nama }}</th>
                            </tr>
                        </template>
                    </table-basic>
                </div>

                <div class="tab-pane fade" id="nav-detail_reviewer" role="tabpanel" aria-labelledby="nav-reviewer-tab">
                    
                </div>

            </div>

        </template>
    </modal-medium>
    <!-- end::detailPenelitian -->

    <!-- begin::detailUsulan -->
    <modal-medium id="detailUsulan" title="Detail Usulan Penelitian dan PkM">
        <template #content>

            <div class="card border border-secondary">
                <div class="card-basic p-4">
                    <label-basic name="Profil Dosen" />

                    <div class="row mb-2 mt-3">
                        <div class="col-sm-3">Nama Dosen</div>
                        <div class="col-sm-9 fw-bold d-block">: {{ pengusul.dosen_nama }}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-3">NIDN</div>
                        <div class="col-sm-9 fw-bold d-block">: {{ pengusul.dosen_nidn }}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">Program Studi</div>
                        <div class="col-sm-9 fw-bold d-block">: {{ pengusul.program_studi }}</div>
                    </div>
                </div>
            </div>

            <nav class="mt-4">
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link" :class="i == 1 ? 'active' : ''" id="nav-detail_usulan_ppm_usulan_1-tab" data-bs-toggle="tab" type="button" role="tab" aria-selected="true" v-for="i in jumlah_usulan" :key="i" @click="detailUsulan(index_usulan, i-1)">Usulan {{ i }}</button>
                </div>
            </nav>

            <div class="card border border-secondary">
                <div class="card-basic p-4">

                    <div class="d-flex justify-content-between mb-4">
                        <label-basic name="Usulan PPM" />
                        <span class="btn btn-sm btn-link p-0" v-if="!detail[0]" @click="detail[0] = !detail[0]">Detail <icon-arrow-bottom /></span>
                        <span class="btn btn-sm btn-link text-danger p-0" v-else @click="detail[0] = !detail[0]">Tutup Detail <icon-arrow-up /></span>
                    </div>

                    <div v-if="!detail[0]">
                        <p>{{ pengusul.judul }}</p>
                        <div class="d-flex justify-content-between">
                            <div>
                                <span class="badge badge-info me-2">{{ pengusul.jenis }}</span>
                                <span class="badge badge-danger" v-if="nilai.kunci == 0">Unreviewed</span>
                                <span class="badge badge-success" v-else>Reviewed</span>
                            </div>
                            <a class="btn btn-sm btn-link p-0" :href="pengusul.link_file_proposal" v-if="pengusul.file_proposal != null">Lihat File Proposal</a>
                            <span v-else class="text-danger">Tidak Ada File Proposal</span>
                        </div>
                    </div>

                    <div v-else>
                        <div class="row mb-2">
                            <div class="col-sm-3">Jenis</div>
                            <div class="col-sm-9 fw-bold d-block">: {{ pengusul.jenis }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-3">Tema</div>
                            <div class="col-sm-9 fw-bold d-block">: {{ pengusul.tema }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-3">Judul</div>
                            <div class="col-sm-9 fw-bold d-block">: {{ pengusul.judul }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-3">Latar Belakang</div>
                            <div class="col-sm-9 fw-bold d-block">: {{ pengusul.latar_belakang }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-3">Tujuan</div>
                            <div class="col-sm-9 fw-bold d-block">: {{ pengusul.tujuan }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-3">Metode</div>
                            <div class="col-sm-9 fw-bold d-block">: {{ pengusul.metode }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-sm-3">Kebaruan</div>
                            <div class="col-sm-9 fw-bold d-block">: {{ pengusul.kebaruan }}</div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-sm-3">Anggota</div>
                            <div class="col-sm-9 fw-bold">
                                <span class="d-block" v-for="(anggota, index) in data_anggota" :key="index">: {{ anggota.anggota_id }} - {{ anggota.anggota_nama }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card border border-secondary mt-4">
                <div class="card-basic p-4">
                    <label-basic name="Penilaian" class="mb-4" />

                    <table-basic>
                        <template #thead>
                            <tr class="fw-bolder bg-light fs-7">
                                <th class="rounded-start ps-5 w-50px">No.</th>
                                <th>Penilaian</th>
                                <th class="w-100px">Bobot (%)</th>
                                <th class="rounded-end w-100px">Skor</th>
                            </tr>
                        </template>

                        <template #tbody>
                            <tr v-for="(d_nilai, i) in data_nilai" :key="i">
                                <th class="ps-5 text-end">{{ i+1 }}</th>
                                <td>
                                    <span class="fw-bold d-block">{{ d_nilai.nama }}</span>
                                    <span v-html="d_nilai.keterangan"></span>
                                </td>
                                <td>{{ d_nilai.bobot }}</td>
                                <td class="pe-5">
                                    <select v-model="d_nilai.skor" :disabled="nilai.kunci == 1" class="form-select w-100px">
                                        <option v-for="i in data_skor" :value="i" :key="i">{{i}}</option>
                                    </select>
                                    <!-- <input type="number" class="form-control w-100px" @change="ubahNilai()"> -->
                                </td>
                            </tr>

                            <tr class="bg-secondary">
                                <td colspan="4" class="text-center rounded">
                                    Total = <span class="fw-bolder">{{ nilai.skor }}</span> ( <span class="fw-bolder">{{nilai.keterangan}}</span> )
                                </td>
                            </tr>
                        </template>
                    </table-basic>

                    <label class="col-form-label pb-0">Catatan :</label>
                    <textarea class="form-control mb-4" required rows="5" v-model="form_catatan"></textarea>

                    <div class="d-flex justify-content-between p-5 pt-0">
                        <div>
                            <table class="mb-1">
                                <tr>
                                    <td style="width: 200px">
                                        1 = Buruk<br>
                                        2 = Sangat Kurang<br>
                                        3 = Kurang<br>
                                    </td>
                                    <td style="width: 200px">
                                        5 = Cukup<br>
                                        6 = Baik<br>
                                        7 = Sangat Baik<br>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div>
                            <button class="btn btn-sm btn-primary w-100px me-2" @click="kunciPengusulanNilai" v-if="nilai.skor > 0 & nilai.kunci == 0">Kunci</button>
                            <button class="btn btn-sm btn-success w-100px" @click="storePengusulanNilai" v-if="nilai.kunci == 0">Simpan</button>
                        </div>
                    </div>
                </div>
            </div>

        </template>
    </modal-medium>
    <!-- end::detailUsulan -->
    <!-- end::modal -->

</template>

<script>

import {indexPengusulan, showPengusulan, reviewerPengusulan} from '@/apis/pengusulan';
import {dataPengusulanAnggota} from '@/apis/pengusulanAnggota';
import {dataPengusulanNilai, storePengusulanNilai, storeCatatanPengusulanNilai, jumlahPengusulanNilai, kunciPengusulanNilai} from '@/apis/pengusulanNilai';
import {indexJadwalPengusulan} from '@/apis/jadwalPengusulan';

export default {
    beforeMount() {
        localStorage.setItem('menu', 'Penelitian dan PkM');
        localStorage.setItem('halaman', 'Reviewer');
    },
    data() {
        return {
            data_pengusul: {},
            pengusul: {},
            data_anggota: {},
            data_nilai: {},
            form_nilai: [],
            form_catatan: '',
            nilai: {
                'skor': 0,
                'keterangan' : ''
            },
            data_keterangan: ['Buruk', 'Sangat Kurang', 'Kurang', 'Cukup', 'Baik', 'Sangat Baik'],
            tr_pengusulan_reviewer_id: 0,
            index_usulan: 0,
            detail: [false, false],
            jumlah_usulan: 0,
            data_skor: [1, 2, 3, 5, 6, 7],
            jadwal_id: 0,
            data_jadwal_pengusulan: [],
        }
    },
    mounted() {
        this.indexJadwalPengusulan();
        this.reviewerPengusulan();
    },
    methods: {
         async indexJadwalPengusulan() {
            const res = await indexJadwalPengusulan();

            this.data_jadwal_pengusulan = res.data.data;
        },
        async reviewerPengusulan() {
            const res = await reviewerPengusulan(this.jadwal_id);

            this.data_pengusul = res.data.data.map(e => {
                e.edit = e.ajukan == 0 ? true : false;
                e.hapus = e.ajukan == 0 ? true : false;

                return e;
            });

            this.jadwal_id = res.data.jadwal_id
        },  
        resetDetail() {
            this.detail.forEach((e, i) => {
                this.detail[i] = false;
            })
        }, 
        detailUsulan(index, usulan = 0) {
            this.index_usulan = index;
            this.resetDetail();
            this.jumlah_usulan = this.data_pengusul[index].jumlah_usulan;
            this.showPengusulan( this.data_pengusul[index].data_usulan[usulan].id );
        },
        async showPengusulan(id) {
            this.pengusul = {};
            const res = await showPengusulan(id);

            this.pengusul = res.data.data;
            this.dataPengusulanAnggota(id);
            this.dataPengusulanNilai(id);
        },
        async dataPengusulanAnggota(tr_pengusulan_id) {
            this.data_anggota = {};
            const res = await dataPengusulanAnggota(tr_pengusulan_id);

            this.data_anggota = res.data.data;
        },
        async dataPengusulanNilai(tr_pengusulan_id) {
            this.data_nilai = {};
            this.nilai = {};
            const res = await dataPengusulanNilai(tr_pengusulan_id);

            this.data_nilai = res.data.data;
            this.nilai = res.data.nilai;
            this.tr_pengusulan_reviewer_id = res.data.tr_pengusulan_reviewer_id;
            this.form_catatan = res.data.catatan;
        },
        ubahNilai() {
            this.nilai.total = 0;
            this.data_nilai.forEach((e, i) => {
                this.nilai.total += ( (e.bobot / 100) * e.skor );
            });

        },
        async storePengusulanNilai() {
            this.data_nilai.forEach(async e => {
                const data = {
                    'tr_pengusulan_reviewer_id' : this.tr_pengusulan_reviewer_id,
                    'dm_form_penilaian_id' : e.id,
                    'skor' : e.skor 
                };

                const res = await storePengusulanNilai(data);
            });

            const data = {
                'tr_pengusulan_reviewer_id' : this.tr_pengusulan_reviewer_id,
                'catatan' : this.form_catatan
            };

            const res = await storeCatatanPengusulanNilai(data);

            console.log( res );

            this.jumlahPengusulanNilai();
        },
        async jumlahPengusulanNilai() {
            const res = await jumlahPengusulanNilai({'tr_pengusulan_reviewer_id' : this.tr_pengusulan_reviewer_id});

            this.dataPengusulanNilai(this.pengusul.id);

            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Nilai berhasil disimpan',
                    showConfirmButton: false,
                    timer: 3000
                })
            }
        },
        async kunciPengusulanNilai() {
            const res = await kunciPengusulanNilai({'tr_pengusulan_reviewer_id' : this.tr_pengusulan_reviewer_id});

            this.dataPengusulanNilai(this.pengusul.id);
            
            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Nilai berhasil Reviewed',
                    showConfirmButton: false,
                    timer: 3000
                })
            }
        }
    }
}

</script>