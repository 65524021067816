import {api, headers} from './api';

const RESOURCE = 'pengusulan';

export const indexPengusulan = (jadwal_id) => api.get(RESOURCE+'?jadwal_id='+jadwal_id, headers);

export const dosenPengusulan = (jadwal_id) => api.get(RESOURCE+'/dosen?jadwal_id='+jadwal_id, headers);

export const showPengusulan = (id) => api.get(RESOURCE+'/'+id, headers);

export const penilaianPengusulan = (id) => api.get(RESOURCE+'/penilaian/'+id, headers);

export const storePengusulan = (data) => api.post(RESOURCE, data, headers);

export const deletePengusulan = (id) => api.delete(RESOURCE+'/'+id, headers);

export const ajukanPengusulan = (id, data = null) => api.post(RESOURCE+'/'+id, data, headers);

export const validasiPengusulan = (data) => api.post(RESOURCE+'/validasi', data, headers);

export const tolakPengusulan = (id) => api.post(RESOURCE+'/tolak/'+id, {}, headers);

export const reviewerPengusulan = (jadwal_id) => api.get(RESOURCE+'/reviewer?jadwal_id='+jadwal_id, headers);

export const suratPerjanjianPengusulan = (data) => api.post(RESOURCE+'/surat_perjanjian', data, headers);

export const uploadFileProposal = (data) => api.post(RESOURCE+'/uploadFileProposal', data, headers);

export const uploadFileSuratPerjanjian = (data) => api.post(RESOURCE+'/uploadFileSuratPerjanjian', data, headers);

export const uploadArtikel = (data) => api.post(RESOURCE+'/uploadArtikel', data, headers);

export const uploadLoa = (data) => api.post(RESOURCE+'/uploadLoa', data, headers);

export const ajukanPerubahan = (id) => api.post(RESOURCE+'/ajukanPerubahan/'+id, {}, headers);

export const validasiPerubahan = (data) => api.post(RESOURCE+'/validasiPerubahan', data, headers);
