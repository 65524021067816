<template>
    <profil-mahasiswa v-if="this.user.peran == 'MAHASISWA'" />
    <profil-dosen v-else-if="this.user.peran == 'DOSEN'" />
    <profil-admin v-else-if="this.user.peran == 'ADMIN'" />

    <!-- begin::modal -->
    <!--begin::Image input-->
    <modal-fullscreen-sm id="modalEditFoto" title="Ganti Foto Profil">
        <template #content>
            <div class="d-flex align-items-center justify-content-center min-h-100 flex-column">
                <div class="image-input image-input-outline" data-kt-image-input="true">
                    <!--begin::Image preview wrapper-->
                    <div class="image-input-wrapper w-125px h-125px"></div>
                    <!--end::Image preview wrapper-->
            
                    <!--begin::Edit button-->
                    <label class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Change avatar">
                        <i class="bi bi-pencil-fill fs-7"></i>
            
                        <!--begin::Inputs-->
                        <input type="file" name="avatar" accept=".png, .jpg, .jpeg" id="fileFoto" />
                        <input type="hidden" name="avatar_remove" />
                        <!--end::Inputs-->
                    </label>
                    <!--end::Edit button-->
            
                    <!--begin::Cancel button-->
                    <span class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Cancel avatar">
                        <i class="bi bi-x fs-2"></i>
                    </span>
                    <!--end::Cancel button-->
            
                    <!--begin::Remove button-->
                    <span class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Remove avatar">
                        <i class="bi bi-x fs-2"></i>
                    </span>
                    <!--end::Remove button-->
                </div>
                
                <button class="btn btn-primary mt-3" @click="processGantiFoto">Ganti Foto</button>
            </div>
        </template>
    </modal-fullscreen-sm>
    <!--end::Image input-->
    <!-- end::modal -->
</template>

<script>
// apis
import { updateFoto } from '@/apis/user';

// views
import ProfilAdmin from '@/views/profil/ProfilAdmin.vue';
import ProfilDosen from '@/views/profil/ProfilDosen.vue';
import ProfilMahasiswa from '@/views/profil/ProfilMahasiswa.vue';

// store
import { getUserData } from '@/store';

export default {
    components: {
        ProfilMahasiswa,
        ProfilDosen,
        ProfilAdmin
    },
    data() {
        return {
            user: {}
        }
    },
    beforeMount() {
        this.user = getUserData();
        localStorage.setItem('menu', 'AISnet');
        localStorage.setItem('halaman', 'Profil');
    },
    methods: {
        async processGantiFoto() {
            try {
                let formData = new FormData();

                var imagefile = document.querySelector('#fileFoto');
                formData.append("file", imagefile.files[0]);

                const response = await updateFoto(formData);
                if( response.status == 200 ) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Foto profil berhasil diganti',
                        showConfirmButton: false,
                        timer: 5000
                    })

                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                }
            } catch (err) {
                console.error(err);
            }
        }
    }
}
</script>