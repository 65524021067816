<template>
    
    <container-double>
        <template #left>
            <alert-basic v-if="dosen.penelitian == 0"
                class="alert-danger"
                message="lengkapi profil anda untuk menambah usulan Penelitian dan PkM" />
            
            <div class="d-flex justify-content-between mb-5">
                <div>
                    <a class="btn btn-sm btn-dark" href="https://drive.google.com/file/d/1dCXEp2_9-bNFhdFfl91PabO4SnjguDPh/view?usp=sharing" target="_blank">SK Pendanaan PPM</a>
                    <a class="btn btn-sm btn-dark ms-2" href="https://drive.google.com/drive/folders/1apGKcRrCTiTVaIrjSFHnvrWdgwqUyLs4?usp=sharing" target="_blank">Template Proposal</a>
                </div>
            </div>

            <alert-basic v-if="jadwal_aktif == false"
                class="alert-danger"
                message="Usulan Penelitian dan PkM belum dimulai" /> 
            
            <div class="alert text-dark d-flex alert-light shadow-sm">
                <span class="svg-icon svg-icon-3 me-3 mb-sm-0 text-dark">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z" fill="currentColor"></path><path d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z" fill="currentColor"></path>
                    </svg>
                </span>
                <div class="pe-0 pe-sm-10">
                    <span class="fs-7">Informasi terkait PPM bisa menghubungi <a target="_blank" href="https://api.whatsapp.com/send?phone=6281808608572"><u>Detila Rostilawati</u></a></span>
                </div>
            </div>

            <!-- filter semester -->
            <div class="d-flex justify-content-between mb-5">
                <div class="d-flex align-items-center gap-2 ms-5">
                    <label class="form-label m-0">Semester</label>
                    <select class="form-select w-auto" v-model="jadwal_id" @change="dosenPengusulan">
                        <option 
                            v-for="jadwal in data_jadwal_pengusulan" 
                            :key="jadwal.id" 
                            :value="jadwal.id">
                            {{jadwal.semester}}
                        </option>
                    </select>
                </div>

                <button v-if="dosen.penelitian == 1 & jadwal_aktif" 
                    class="btn btn-sm btn-primary ms-2" 
                    data-bs-toggle="modal" 
                    data-bs-target="#modalPenelitian" 
                    @click="form_pengusulan = {}">Tambah Usulan</button>
            </div>
            
            <div v-if="dosen.penelitian == 1">
                <div class="card mb-3 shadow-sm" v-for="(pengusulan, i) in data_pengusulan" :key="i">
                    <div class="card-body p-6">
                        <!-- begin::jenis & status -->
                        <div class="d-flex justify-content-between mb-4">
                            <span class="badge badge-info">{{ pengusulan.jenis }}</span>
                            <div>
                                <span class="badge" :class="['DISETUJUI', 'PERUBAHAN DISETUJUI'].includes(pengusulan.status) ? 'badge-success' : 'badge-secondary'">{{ pengusulan.status }}</span>
                            </div>
                        </div>
                        <!-- end::jenis & status -->
    
                        <p class="m-0 mb-3">{{ pengusulan.judul }}</p>
    
                        <div class="d-flex justify-content-between mt-3">
                            <div>
                                <button class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#modalDetail" @click="showPengusulan(pengusulan.id), detail[0] = false">Detail</button>
                                <button class="btn btn-sm btn-dark ms-3" v-if="pengusulan.ajukan == 1" data-bs-toggle="modal" data-bs-target="#modalLog" @click="showLog(i)">Log</button>
                                <button class="btn btn-sm btn-success ms-3" v-if="pengusulan.ajukan == 0 & pengusulan.file_proposal != null" @click="ajukanPengusulan(pengusulan.id)">Ajukan</button>
                                <button class="btn btn-sm btn-secondary ms-3" v-if="pengusulan.validasi_rektor == 1 & pengusulan.edit_status == 0" @click="ajukanPerubahan(pengusulan.id)">Ajukan Perubahan</button>
                            </div>
                            <div>
                                <button class="btn btn-sm btn-link text-gray-800" data-bs-toggle="modal" data-bs-target="#modalPenelitian" @click="showPengusulan(pengusulan.id)" v-if="pengusulan.edit">Edit</button>
                                <button class="btn btn-sm btn-link text-danger ms-3" v-if="pengusulan.hapus" @click="deletePengusulan(pengusulan.id)">Hapus</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </template>
        
        <template #right>
            
            <card-basic>
                <template #content>

                    <div class="p-5">
                        <label-basic name="Profil Dosen" />

                        <div class="mb-2 mt-3">
                            <span class="fs-8 text-gray-700 d-inline-block w-150px">NIDN</span>
                            <span class="fs-7 fw-bold text-gray-800">{{ dosen.nidn }}</span>
                        </div>

                        <div class="mb-2">
                            <span class="fs-8 text-gray-700 d-inline-block w-150px">Nama Lengkap</span>
                            <span class="fs-7 fw-bold text-gray-800">{{ dosen.nama }}</span>
                        </div>

                        <div class="mb-2">
                            <span class="fs-8 text-gray-700 d-inline-block w-150px">No. HP</span>
                            <span class="fs-7 fw-bold text-gray-800">{{ dosen.no_hp }}</span>
                        </div>
                    </div>

                </template>
            </card-basic>

            <card-basic>
                <template #content>
                    <div class="p-5">
                        <label-basic name="Program Studi" />

                        <div class="mb-2 mt-3">
                            <span class="fs-8 text-gray-700 d-inline-block w-150px">Program Studi</span>
                            <span class="fs-7 fw-bold text-gray-800">{{ dosen.program_studi }}</span>
                        </div>

                        <div class="mb-2">
                            <span class="fs-8 text-gray-700 d-inline-block w-150px">Kelompok Keahlian</span>
                            <span class="fs-7 fw-bold text-gray-800">{{ dosen.kelompok_keahlian }}</span>
                        </div>

                    </div>
                </template>
            </card-basic>

            <card-basic>
                <template #content>
                    <div class="p-5">
                        <label-basic name="Penelitian" />

                        <div class="mt-3">
                            <div class="mb-2" v-for="(penelitian_dosen, i) in data_penelitian_dosen" :key="i">
                                <span class="fs-8 text-gray-700 d-inline-block w-150px">{{ penelitian_dosen.nama }}</span>
                                <span class="fs-7 fw-bold text-gray-800">{{ penelitian_dosen.url }}</span>
                            </div>
                        </div>

                    </div>
                </template>
            </card-basic>

        </template>
    </container-double>

    <!-- begin::modal -->
    <!-- begin::modalPenelitian -->
    <modal-fullscreen-sm id="modalPenelitian" title="Tambah Usulan">
        <template #content>
            <form @submit.prevent="storePengusulan">
                <div class="p-1 pb-4 pt-0">
                    <div class="form-group mb-2">
                        <label for="jenis" class="col-form-label pb-2">Jenis</label>
                        <select name="jenis" id="jenis" class="form-select" v-model="form_pengusulan.jenis" required>
                            <option v-for="(jenis, index) in daftar_jenis" :key="index" :value="jenis">{{ jenis }}</option>
                        </select>
                    </div>
                    <div class="form-group mb-2">
                        <label for="dm_kelompok_keahlian_id" class="col-form-label pb-2">Tema</label>
                        <select name="dm_kelompok_keahlian_id" id="dm_kelompok_keahlian_id" class="form-select" v-model="form_pengusulan.dm_kelompok_keahlian_id" required>
                            <option v-for="(keahlian, i) in data_kelompok_keahlian" :key="i" :value="keahlian.id">{{ keahlian.nama }}</option>
                        </select>
                    </div>
                    <div class="form-group mb-2">
                        <label for="skema_iuran" class="col-form-label pb-2">Skema Luaran</label>
                        <select name="skema_iuran" id="skema_iuran" class="form-select" v-model="form_pengusulan.dm_skema_iuran_id" required>
                            <option v-for="(iuran, index) in data_skema_iuran" :key="index" :value="iuran.id">{{ iuran.target_iuran }} - Rp.{{ iuran.biaya }}</option>
                        </select>
                    </div>
                    <div class="form-group mb-2">
                        <label for="judul" class="col-form-label pb-2">Judul</label>
                        <textarea name="judul" id="judul" rows="4" class="form-control form-control-sm" v-model="form_pengusulan.judul" required></textarea>
                    </div>
                    <div class="form-group mb-2">
                        <label for="latar_belakang" class="col-form-label pb-2">Latar Belakang</label>
                        <textarea name="latar_belakang" id="latar_belakang" rows="8" class="form-control form-control-sm" v-model="form_pengusulan.latar_belakang" required></textarea>
                    </div>
                    <div class="form-group mb-2">
                        <label for="tujuan" class="col-form-label pb-2">Tujuan</label>
                        <textarea name="tujuan" id="tujuan" rows="4" class="form-control form-control-sm" v-model="form_pengusulan.tujuan" required></textarea>
                    </div>
                    <div class="form-group mb-2">
                        <label for="metode" class="col-form-label pb-2">Metode</label>
                        <textarea name="metode" id="metode" rows="4" class="form-control form-control-sm" v-model="form_pengusulan.metode" required></textarea>
                    </div>
                    <div class="form-group mb-2">
                        <label for="kebaruan" class="col-form-label pb-2">Kebaruan</label>
                        <textarea name="kebaruan" id="kebaruan" rows="4" class="form-control form-control-sm" v-model="form_pengusulan.kebaruan" required></textarea>
                    </div>
                    <div class="form-group mb-2">
                        <label for="mitra" class="col-form-label pb-2">Mitra</label>
                        <input type="text" name="mitra" id="mitra" class="form-control form-control-sm" v-model="form_pengusulan.mitra" required>
                    </div>
                    <div class="form-group mb-2">
                        <label for="mitra" class="col-form-label pb-2">Status Mitra</label>
                        <select class="form-select" v-model="form_pengusulan.mitra_status">
                            <option v-for="(status, i) in mitra_status" :key="i" :value="status.kode">{{ status.keterangan }}</option>
                        </select>
                    </div>
                    <div class="form-group mb-2">
                        <label for="penerbit" class="col-form-label pb-2">Rencana Publikasi</label>
                        <input type="text" name="penerbit" id="penerbit" class="form-control form-control-sm" v-model="form_pengusulan.penerbit" required>
                    </div>
                    <div class="mt-7">
                        <input type="submit" value="Simpan" class="btn btn-success d-block w-100 w-sm-auto">
                    </div>
                </div>
            </form>
        </template>
    </modal-fullscreen-sm>
    <!-- end::modalPenelitian -->

    <!-- begin::modalDetail -->
    <modal-medium id="modalDetail" title="Detail Usulan Penelitian">
        <template #content>
            
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link active" id="nav-detail_penelitian-tab" data-bs-toggle="tab" data-bs-target="#nav-detail_penelitian" type="button" role="tab" aria-controls="nav-detail_penelitian" aria-selected="true">Penelitian</button>
                    <button class="nav-link" id="nav-detail_anggota-tab" data-bs-toggle="tab" data-bs-target="#nav-detail_anggota" type="button" role="tab" aria-controls="nav-detail_anggota" aria-selected="true">Anggota</button>
                    <button class="nav-link" id="nav-detail_surat_perjanjian-tab" data-bs-toggle="tab" data-bs-target="#nav-detail_surat_perjanjian" type="button" role="tab" aria-controls="nav-detail_surat_perjanjian" aria-selected="true" v-if="pengusulan.no_surat_perjanjian != null">Surat Perjanjian</button>
                    <button class="nav-link" id="nav-detail_artikel-tab" data-bs-toggle="tab" data-bs-target="#nav-detail_artikel" type="button" role="tab" aria-controls="nav-detail_artikel" aria-selected="true" v-if="pengusulan.no_surat_perjanjian != null">Luaran PPM</button>
                    <button class="nav-link" id="nav-detail_usulan_pencairan-tab" data-bs-toggle="tab" data-bs-target="#nav-detail_usulan_pencairan" type="button" role="tab" aria-controls="nav-detail_usulan_pencairan" aria-selected="true" v-if="pengusulan.no_surat_perjanjian != null">Usulan Pencairan Dana</button>
                </div>
            </nav>

            <div class="tab-content p-1 bg-white border border-secondary border-top-0" id="nav-tabContent">
                <div class="tab-pane fade show active p-4" id="nav-detail_penelitian" role="tabpanel" aria-labelledby="nav-penelitian-tab">
                    <div class="row mb-2">
                        <div class="col-sm-2">Jenis</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusulan.jenis }}</span>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-2">Tema</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusulan.tema }}</span>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-2">Judul</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusulan.judul }}</span>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-2">Latar Belakang</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusulan.latar_belakang }}</span>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-2">Tujuan</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusulan.tujuan }}</span>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-2">Metode</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusulan.metode }}</span>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-2">Kebaruan</div>
                        <div class="col-sm-10">
                            <span class="fw-bold d-block">{{ pengusulan.kebaruan }}</span>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-2">File Proposal</div>
                        <div class="col-sm-10">
                            <div class="d-flex justify-content-between" v-if="!detail[0]">
                                <a class="btn btn-sm btn-link p-0" :href="pengusulan.link_file_proposal" target="_blank" v-if="pengusulan.file_proposal != null">Lihat</a>
                                <span class="text-danger" v-else>Belum Ada File Proposal</span>
                                <a class="btn btn-sm btn-link p-0" @click="detail[0] = !detail[0]">Upload Proposal</a>
                            </div>
                            <div v-else>
                                <form class="d-flex mt-2" @submit.prevent="uploadFileProposal">
                                    <input type="file" class="form-control form-control-sm" id="file_proposal" required>
                                    <input type="submit" value="Upload" class="btn btn-sm btn-success ms-2">
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="nav-detail_anggota" role="tabpanel" aria-labelledby="nav-anggota-tab">

                    <form @submit.prevent="storePengusulanAnggota" class="d-flex p-4" v-if="pengusulan.ajukan == 0">
                        <select id="dm_dosen_id" name="dm_dosen_id" class="form-select me-3" v-model="form_anggota.anggota_id">
                            <option v-for="(dosen, i) in data_dosen" :key="i" :value="dosen.id">{{ dosen.nidn }} - {{ dosen.nama }}</option>
                        </select>
                        <input type="submit" value="Tambah" class="btn btn-sm btn-primary">
                    </form>

                    <table-basic>
                        <template #thead>
                            <tr class="fw-bolder bg-light fs-7">
                                <th class="rounded-start ps-5 w-md-50px">No.</th>
                                <th>Anggota</th>
                                <th class="rounded-end ps-5 w-md-110px">Aksi</th>
                            </tr>
                        </template>

                        <template #tbody>
                            <tr v-for="(anggota, i) in data_anggota" :key="i">
                                <th class="ps-5 text-end">{{ i+1 }}</th>
                                <th>{{ anggota.anggota_nidn }} - {{ anggota.anggota_nama }}</th>
                                <th class="pe-5">
                                    <button class="btn btn-sm btn-danger" v-if="pengusulan.ajukan == 0" @click="deletePengusulanAnggota(anggota.id)">Hapus</button>
                                </th>
                            </tr>
                        </template>
                    </table-basic>
                </div>

                <div class="tab-pane fade" id="nav-detail_surat_perjanjian" role="tabpanel" aria-labelledby="nav-surat_perjanjian-tab">
                    <div class="row p-4">
                        <div class="col-sm-3">Surat Perjanjian</div>
                        <div class="col-sm-9">
                            <a :href="pengusulan.link_surat_perjanjian" target="_blank" class="btn btn-sm btn-danger">Template Surat Perjanjian</a>
                            <a :href="pengusulan.file_surat_perjanjian" v-if="pengusulan.file_surat_perjanjian != '-'" target="_blank" class="btn btn-sm btn-success ms-2">Lihat</a>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="nav-detail_artikel" role="tabpanel" aria-labelledby="nav-surat_perjanjian-tab">
                    <form class="p-4" @submit.prevent="uploadLoa">
                        <div class="form-group row mb-2">
                            <label class="col-form-label col-sm-3">File LOA</label>
                            <div class="col-sm-9">
                                <input type="file" id="fileLoa" class="form-control form-control-sm" required>
                                <a class="btn btn-sm btn-secondary mt-2" :href="pengusulan.file_loa" target="_blank" v-if="pengusulan.file_loa != '-'">Lihat</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col-sm-9">
                                <input type="submit" value="Upload" class="btn btn-sm btn-success">
                            </div>
                        </div>
                    </form>

                    <form class="p-4" @submit.prevent="uploadArtikel">
                        <div class="form-group row mb-2">
                            <label class="col-form-label col-sm-3">File Artikel</label>
                            <div class="col-sm-9">
                                <input type="file" id="fileArtikel" class="form-control form-control-sm" required>
                                <a class="btn btn-sm btn-secondary mt-2" :href="pengusulan.file_artikel" target="_blank" v-if="pengusulan.file_artikel != '-'">Lihat</a>
                            </div>
                        </div>
                        <div class="form-group row mb-2">
                            <label class="col-form-label col-sm-3">URL Artikel</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form_artikel.url_artikel" required>
                                <a class="btn btn-sm btn-secondary mt-2" :href="pengusulan.url_artikel" target="_blank" v-if="pengusulan.url_artikel != '-'">Lihat</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col-sm-9">
                                <input type="submit" value="Upload" class="btn btn-sm btn-success">
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane fade" id="nav-detail_usulan_pencairan" role="tabpanel" aria-labelledby="nav-surat_perjanjian-tab">
                    <form class="p-4" @submit.prevent="storePengusulanDana">
                        <div class="row form-group mb-3">
                            <label class="col-form-label col-sm-3">Tahap</label>
                            <div class="col-sm-9">
                                <select class="form-select" v-model="form_pengusulan_dana.tahap">
                                    <option value="1">Pencairan Tahap 1</option>
                                    <option value="2">Pencairan Tahap 2</option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col-sm-9">
                                <input type="submit" value="Tambah" class="btn btn-sm btn-success">
                            </div>
                        </div>
                    </form>

                    <div v-if="pengusulan.no_surat_perjanjian != null">
                        <table-basic class="mt-5">
                            <template #thead>
                                <tr class="fw-bolder bg-light fs-7">
                                    <th class="rounded-start ps-5 w-md-50px">No.</th>
                                    <th>Tahap</th>
                                    <th>Biaya</th>
                                    <th>Status</th>
                                    <th class="rounded-end ps-5">Aksi</th>
                                </tr>
                            </template>
    
                            <template #tbody>
                                <tr v-for="(pengusulan_dana, i) in data_pengusulan_dana" :key="i">
                                    <td class="text-end">{{ i+1 }}</td>
                                    <td>Tahap {{ pengusulan_dana.tahap }}</td>
                                    <td>{{ pengusulan_dana.biaya }}</td>
                                    <td>
                                        <span class="badge" :class="['DISETUJUI', 'PERUBAHAN DISETUJUI'].includes(pengusulan_dana.keterangan) ? 'badge-success' : 'badge-secondary'">
                                            {{ pengusulan_dana.keterangan }}
                                        </span>
                                    </td>
                                    <td class="d-flex">
                                        <div v-if="pengusulan_dana.tahap == 1">
                                            <button class="btn btn-sm btn-success me-2" v-if="pengusulan_dana.status == 0 & pengusulan.file_loa != '-'" @click="ajukanPengusulanDana(pengusulan_dana.id)">Ajukan</button>
                                        </div>
                                        <div v-else>
                                            <button class="btn btn-sm btn-success me-2" v-if="pengusulan_dana.status == 0 & pengusulan.file_loa != '-' & pengusulan.file_artikel != '-' & pengusulan.url_artikel != '-' " @click="ajukanPengusulanDana(pengusulan_dana.id)">Ajukan</button>
                                        </div>
                                        <button class="btn btn-sm btn-danger" v-if="pengusulan_dana.status == 0" @click="deletePengusulanDana(pengusulan_dana.id)">Hapus</button>
                                    </td>
                                </tr>
                            </template>
                        </table-basic>
    
                        <div v-if="pengusulan.file_loa == '-' | pengusulan.file_artikel == '-' | pengusulan.url_artikel == '-'">
                            <div class="alert alert-danger m-4 text-center">Untuk melakukan pengajuan pencairan pastikan <b>File LOA</b>, <b>File </b> dan <b>URL Artikel</b> telah terisi di tab artikel</div>
                        </div>
                    </div>
                </div>
            </div>

        </template>
    </modal-medium>
    <!-- end::modalDetail -->

    <!-- begin::modalLog -->
    <modal-fullscreen-sm id="modalLog" title="Log">
        <template #content>
            <div class="card border mb-2" v-for="(log, i) in data_log" :key="i">
                <div class="card-body p-2 px-3">
                    <span class="mb-1 d-block">{{ log.status }}</span>
                    <small class="text-muted">{{ log.waktu }}</small>
                </div>
            </div>
        </template>
    </modal-fullscreen-sm>
    <!-- end::modalLog -->
    <!-- end::modal -->
</template>

<script>
import {indexDosen, profilDosen} from '@/apis/dosen2';
import {indexSkemaIuran} from '@/apis/skemaIuran';
import {dosenPengusulan, storePengusulan, deletePengusulan, ajukanPengusulan, showPengusulan, uploadFileProposal, uploadArtikel, uploadLoa, ajukanPerubahan} from '@/apis/pengusulan';
import {semuaKelompokKeahlian} from '@/apis/kelompokKeahlian';
import {dataPengusulanAnggota, storePengusulanAnggota, deletePengusulanAnggota} from '@/apis/pengusulanAnggota';
import {storePengusulanDana, dataPengusulanDana, deletePengusulanDana, ajukanPengusulanDana} from '@/apis/pengusulanDana';
import {dataPenelitianDosen} from '@/apis/penelitianDosen';
import {jadwalAktif, indexJadwalPengusulan} from '@/apis/jadwalPengusulan';

import {formatDate} from '@/helpers/waktu';

export default {
    beforeMount() {
        localStorage.setItem('menu', 'Penelitian dan PkM');
        localStorage.setItem('halaman', 'Usulan');
        this.jadwalAktif();
    },
    data() {
        return {
            daftar_jenis : ['PENELITIAN', 'PENGABDIAN'],
            mitra_status: [
                {'kode' : 0, 'keterangan' : 'Belum Bekerja Sama'},
                {'kode' : 1, 'keterangan' : 'Telah Bekerja Sama'},
            ],
            dosen: {},
            data_skema_iuran: {},
            form_penelitian: {},
            form_pengusulan: {},
            data_pengusulan: {},
            data_kelompok_keahlian: {},
            pengusulan: {},
            data_dosen: {},
            form_anggota: {},
            data_anggota: {},
            form_pengusulan_dana: {},
            data_pengusulan_dana: {},
            data_penelitian_dosen: {},
            form_artikel: {},
            detail: [false],
            jadwal_aktif: false,
            data_log: {},
            data_jadwal_pengusulan: {},
            jadwal_id: 0,
        }
    },
    mounted() {
        this.indexJadwalPengusulan()
        this.profilDosen();
        this.indexSkemaIuran();
        this.dosenPengusulan();
        this.semuaKelompokKeahlian();
        this.indexDosen();
    },  
    methods: {
        async indexJadwalPengusulan() {
            const res = await indexJadwalPengusulan();

            this.data_jadwal_pengusulan = res.data.data;
        },
        async jadwalAktif() {
            const res = await jadwalAktif();

            this.jadwal_aktif = res.data.jadwal_aktif;
        },
        async profilDosen() {
            const res = await profilDosen();

            this.dosen = res.data.data;
            this.dataPenelitianDosen(this.dosen.id);
        },
        async indexSkemaIuran() {
            const res = await indexSkemaIuran();

            this.data_skema_iuran = res.data.data;
        },
        async dosenPengusulan() {
            const res = await dosenPengusulan(this.jadwal_id);

            this.data_pengusulan = res.data.data.map(e => {
                e.edit = e.ajukan == 0 ? true : e.edit_status == 2 ? e.edit_tanggal == null ? true : false: false;
                e.hapus = e.ajukan == 0 ? true : false;

                return e;
            });

            this.jadwal_id = res.data.jadwal_id

        },
        async semuaKelompokKeahlian() {
            const res = await semuaKelompokKeahlian();

            this.data_kelompok_keahlian = res.data.data;
        },
        async storePengusulan() {

            try {
                const res = await storePengusulan(this.form_pengusulan);

                if( res.status == 200 ) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Form usulan penelitian berhasil disimpan',
                        showConfirmButton: false,
                        timer: 5000
                    });

                    this.dosenPengusulan();
                    this.form_pengusulan = {};
                }
            } catch (err) {
                Swal.fire({
                    icon: 'error',
                    text: 'Form usulan penelitian gagal disimpan',
                    showConfirmButton: false,
                    timer: 5000
                });

                console.error(err);
            }
        },
        async deletePengusulan(id) {
            try {
                const res = await deletePengusulan(id);

                if( res.status == 200 ) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Form usulan penelitian berhasil dihapus',
                        showConfirmButton: false,
                        timer: 5000
                    });

                    this.dosenPengusulan();
                }
            } catch (err) {
                Swal.fire({
                    icon: 'error',
                    text: 'Form usulan penelitian gagal dihapus',
                    showConfirmButton: false,
                    timer: 5000
                });

                console.error(err);
            }
        },
        async ajukanPengusulan(id) {
            try {
                const res = await ajukanPengusulan(id);

                if( res.status == 200 ) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Form usulan penelitian berhasil diajukan',
                        showConfirmButton: false,
                        timer: 5000
                    });

                    this.dosenPengusulan();
                }
            } catch (err) {
                Swal.fire({
                    icon: 'error',
                    text: 'Form usulan penelitian gagal diajukan',
                    showConfirmButton: false,
                    timer: 5000
                });

                console.error(err);
            }
        },
        async showPengusulan(id) {
            const res = await showPengusulan(id);

            this.pengusulan = res.data.data;
            this.form_pengusulan = this.pengusulan;
            this.dataPengusulanAnggota(this.pengusulan.id);
            this.dataPengusulanDana(this.pengusulan.id);
        },
        async indexDosen() {
            const res = await indexDosen();

            this.data_dosen = res.data.data;
        },
        async storePengusulanAnggota() {
            try {
                this.form_anggota.tr_pengusulan_id = this.pengusulan.id;
                this.form_anggota.status = 'DOSEN';

                const res = await storePengusulanAnggota(this.form_anggota);
                
                if( res.status == 200 ) {
                    Swal.fire({
                        icon: 'success',
                        text: 'Anggota berhasil ditambahkan',
                        showConfirmButton: false,
                        timer: 3000
                    })
                    
                    this.form_anggota = {};
                    this.dataPengusulanAnggota(this.pengusulan.id);
                }
            } catch (err) {
                console.error( err );

                Swal.fire({
                    icon: 'error',
                    text: 'Anggota gagal ditambahkan',
                    showConfirmButton: false,
                    timer: 3000
                })
            }
        },
        async dataPengusulanAnggota(tr_pengusulan_id) {
            const res = await dataPengusulanAnggota(tr_pengusulan_id);

            this.data_anggota = res.data.data;
        },
        async deletePengusulanAnggota(id) {
            const res = await deletePengusulanAnggota(id);

            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Anggota berhasil dihapus',
                    showConfirmButton: false,
                    timer: 3000
                });

                this.dataPengusulanAnggota(this.pengusulan.id);
            }
        },
        async storePengusulanDana() {
            this.form_pengusulan_dana.tr_pengusulan_id = this.pengusulan.id;
            const res = await storePengusulanDana(this.form_pengusulan_dana);

            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Usulan dana berhasil ditambahkan',
                    showConfirmButton: false,
                    timer: 3000
                });

                this.dataPengusulanDana(this.pengusulan.id);
            }
        },
        async dataPengusulanDana(tr_pengusulan_td) {
            const res = await dataPengusulanDana(tr_pengusulan_td);

            this.data_pengusulan_dana = res.data.data;
        },
        async deletePengusulanDana(id) {
            const res = await deletePengusulanDana(id);

            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Usulan dana berhasil dihapus',
                    showConfirmButton: false,
                    timer: 3000
                });

                this.dataPengusulanDana(this.pengusulan.id);
            }
        },
        async ajukanPengusulanDana(id) {
            const res = await ajukanPengusulanDana(id);

            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Usulan dana berhasil diajukan',
                    showConfirmButton: false,
                    timer: 3000
                });

                this.dataPengusulanDana(this.pengusulan.id);
            }
        },
        async dataPenelitianDosen(id) {
            const res = await dataPenelitianDosen(id);

            this.data_penelitian_dosen = res.data.data;
        },
        async uploadLoa() {
            const fileLoa = document.querySelector('#fileLoa');
            
            let formData = new FormData();
            formData.append('id', this.pengusulan.id);
            formData.append('file', fileLoa.files[0]);

            const res = await uploadLoa(formData);

            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Fila LOA berhasil diupload',
                    showConfirmButton: false,
                    timer: 3000
                });

                
                this.showPengusulan(this.pengusulan.id);
            }
        },
        async uploadArtikel() {
            const fileArtikel = document.querySelector('#fileArtikel');

            let formData = new FormData();
            formData.append('id', this.pengusulan.id);
            formData.append('file', fileArtikel.files[0]);
            formData.append('url_artikel', this.form_artikel.url_artikel);

            const res = await uploadArtikel(formData);

            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Fila Artikel berhasil diupload',
                    showConfirmButton: false,
                    timer: 3000
                });

                this.showPengusulan(this.pengusulan.id);
            }
        },
        ajukanPerubahan(id) {
            Swal.fire({
                icon: 'question',
                title: 'Perubahan Usulan',
                text: 'Ajukan Perubahan Usulan ?',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                confirmButtonText: 'Ajukan'
            }).then(async res => {
                if( res.isConfirmed ) {
                    const res = await ajukanPerubahan(id);
        
                    if( res.status == 200 ) {
                        Swal.fire({
                            icon: 'success',
                            text: 'Perubahan berhasil diajukan',
                            showConfirmButton: false,
                            timer: 3000
                        });
        
                        this.dosenPengusulan();
                    }
                }
            })
        },
        async uploadFileProposal() {
            const file = document.querySelector('#file_proposal');
            let formData = new FormData();
            formData.append('id', this.pengusulan.id);
            formData.append('file', file.files[0]);

            const res = await uploadFileProposal(formData);

            if( res.status == 200) {
                Swal.fire({
                    icon: 'success',
                    text: 'File Proposal berhasil diupload',
                    showConfirmButton: false,
                    timer: 3000
                });

                this.dosenPengusulan();
                this.detail[0] = false;
                this.showPengusulan(this.pengusulan.id);
            }
        },
        showLog(index) {
            this.data_log = this.data_pengusulan[index].log.map(e => {
                e.waktu = formatDate(e.created_at);

                return e;
            });
        }
    }
}
</script>