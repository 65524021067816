import { createRouter, createWebHistory } from "vue-router";
import { loggedIn } from "@/store";

// auth
import AuthLoginView from "@/views/auth/LoginView.vue";

// absen
import AbsenView from "@/views/absen/AbsenView.vue";

// dasbor
import DasborView from "@/views/dasbor/DasborView.vue";

// hotline
import HotlineView from "@/views/hotline/HotlineView.vue";

// jadwal kuliah
import JadwalKuliahView from "@/views/jadwalKuliah/JadwalKuliahView.vue";

// penilaian ujian
import UtsView from "@/views/penilaianUjian/uts/UtsView.vue";
import UtsSusulanView from "@/views/penilaianUjian/uts-susulan/UtsSusulanView.vue";
import DetailUtsView from "@/views/penilaianUjian/uts/DetailUtsView.vue";
import DetailUtsSusulanView from "@/views/penilaianUjian/uts-susulan/DetailUtsSusulanView.vue";
import UasView from "@/views/penilaianUjian/uas/UasView.vue";
import DetailUasView from "@/views/penilaianUjian/uas/DetailUasView.vue";
import MonitoringPenilaianView from "@/views/penilaianUjian/monitoring/MonitoringPenilaianView.vue";
import MonitoringPenilaianAkhirView from "@/views/penilaianUjian/monitoring/MonitoringPenilaianAkhirView.vue";
import KonfigurasiJadwalView from "@/views/penilaianUjian/monitoring/KonfigurasiJadwalView.vue";
import KonfigurasiNilaiView from "@/views/penilaianUjian/monitoring/KonfigurasiNilaiView.vue";
import DetailMonitoringPenilaianUTS from "@/views/penilaianUjian/monitoring/DetailMonitoringPenilaianUTS.vue";
import DetailMonitoringPenilaianAkhir from "@/views/penilaianUjian/monitoring/KonfigurasiNilaiView.vue";
import MonitoringPencairanUTSView from "@/views/penilaianUjian/monitoring/MonitoringPencairanUTSView.vue";

// kontrak perkuliahan
import KontrakPerkuliahanView from "@/views/kontrakPerkuliahan/KontrakPerkuliahanView.vue";
import MonitoringKontrakPerkuliahanView from "@/views/kontrakPerkuliahan/MonitoringKontrakPerkuliahanView.vue";
import DetailKontrakPerkuliahanView from "@/views/kontrakPerkuliahan/DetailKontrakPerkuliahanView.vue";

// jadwal uts
import JadwalUtsView from "@/views/jadwalUts/JadwalUtsView.vue";

// jadwal uas
import JadwalUasView from "@/views/jadwalUas/JadwalUasView.vue";

// jurusan
import JurusanView from "@/views/jurusan/JurusanView.vue";

// keuangan
import KeuanganView from "@/views/keuangan/KeuanganView.vue";

// khs
import KhsView from "@/views/khs/KhsView.vue";

// lembaga
import LembagaView from "@/views/lembaga/LembagaView.vue";

// penelitian
import PenelitianView from "@/views/penelitian/PenelitianView.vue";
import PenelitianReviewer from "@/views/penelitian/PenelitianReviewer.vue";
import PenelitianAdmin from "@/views/penelitian/PenelitianAdmin";
import PenelitianValidasi from "@/views/penelitian/PenelitianValidasi";
import PenelitianPencairan from "@/views/penelitian/PenelitianPencairan";

// pengumuman
import PengumumanView from "@/views/pengumuman/PengumumanView.vue";

// Perwalian
import PerwalianView from "@/views/perwalian/PerwalianView.vue";

// profil
import ProfilView from "@/views/profil/ProfilView.vue";

// program studi
import ProgramStudiView from "@/views/programStudi/ProgramStudiView.vue";
import ProgramStudiDetail from "@/views/programStudi/ProgramStudiDetail.vue";

// pusat kajian
import PusatKajianView from "@/views/pusatKajian/PusatKajianView";

// transkrip
import TranskripView from "@/views/transkrip/TranskripView.vue";

// user
import UserAdminView from "@/views/user/userAdminView";
import UserDosenView from "@/views/user/userDosenView";
import userMahasiswaView from "@/views/user/userMahasiswaView";

// pengajuan-ijazah
import PengajuanIjazah from "@/views/ijazah/PengajuanIjazahView";
import VerifikasiIjazahBAAK from "@/views/ijazah/VerifikasiIjazahBAAKView.vue";
import ArsipIjazah from "@/views/ijazah/ArsipIjazahView.vue";
import MonitoringPenilaianUTSSusulanView from "@/views/penilaianUjian/monitoring/MonitoringPenilaianUTSSusulanView.vue";
import DetailMonitoringUtsSusulanView from "@/views/penilaianUjian/monitoring/DetailMonitoringUtsSusulanView.vue";
import MonitoringPencairanUTSSusulanView from "@/views/penilaianUjian/monitoring/MonitoringPencairanUTSSusulanView.vue";
import UTSMahasiswaView from "@/views/penilaianUjian/mahasiswa/UTSMahasiswaView.vue";

// Penilaian Hasil Belajar
import DaftarMatkulUTSView from "@/views/penilaianHasilBelajar/DaftarMatkulUTSView.vue";
import DetailMatkulUTSView from "@/views/penilaianHasilBelajar/DetailMatkulUTSView.vue";
import MonitoringDaftarMatkulUTSView from "@/views/penilaianHasilBelajar/admin/MonitoringDaftarMatkulUTSView.vue";
import MonitoringDetailMatkulUTSView from "@/views/penilaianHasilBelajar/admin/MonitoringDetailMatkulUTSView.vue";
import DaftarMatkulUTSSusulanView from "@/views/penilaianHasilBelajar/DaftarMatkulUTSSusulanView.vue";
import DetailMatkulUTSSusulanView from "@/views/penilaianHasilBelajar/DetailMatkulUTSSusulanView.vue";
import MonitoringDaftarMatkulUTSSusulanView from "@/views/penilaianHasilBelajar/admin/MonitoringDaftarMatkulUTSSusulanView.vue";
import MonitoringDetailMatkulUTSSusulanView from "@/views/penilaianHasilBelajar/admin/MonitoringDetailMatkulUTSSusulanView.vue";
import DaftarMatkulUASView from "@/views/penilaianHasilBelajar/DaftarMatkulUASView.vue";
import DetailMatkulUASView from "@/views/penilaianHasilBelajar/DetailMatkulUASView.vue";
import MonitoringDaftarMatkulUASView from "@/views/penilaianHasilBelajar/admin/MonitoringDaftarMatkulUASView.vue";
import MonitoringDetailMatkulUASView from "@/views/penilaianHasilBelajar/admin/MonitoringDetailMatkulUASView.vue";
import DaftarMatkulUASSusulanView from "@/views/penilaianHasilBelajar/DaftarMatkulUASSusulanView.vue";
import DetailMatkulUASSusulanView from "@/views/penilaianHasilBelajar/DetailMatkulUASSusulanView.vue";
import MonitoringDaftarMatkulUASSusulanView from "@/views/penilaianHasilBelajar/admin/MonitoringDaftarMatkulUASSusulanView.vue";
import MonitoringDetailMatkulUASSusulanView from "@/views/penilaianHasilBelajar/admin/MonitoringDetailMatkulUASSusulanView.vue";
import UASMahasiswaView from "@/views/penilaianUjian/mahasiswa/UASMahasiswaView.vue";
import TabelPencairanUTSView from "@/views/penilaianHasilBelajar/keuangan/TabelPencairanUTSView.vue";
import TabelPencairanUTSSusulanView from "@/views/penilaianHasilBelajar/keuangan/TabelPencairanUTSSusulanView.vue";
import TabelPencairanUASView from "@/views/penilaianHasilBelajar/keuangan/TabelPencairanUASView.vue";
import TabelPencairanUASSusulanView from "@/views/penilaianHasilBelajar/keuangan/TabelPencairanUASSusulanView.vue";

const routes = [
  // START Penilaian Hasil Belajar Baru
  // UTS
  {
    path: "/penilaian/uts",
    component: DaftarMatkulUTSView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uts/detail",
    component: DetailMatkulUTSView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uts/monitoring",
    component: MonitoringDaftarMatkulUTSView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uts/monitoring-detail",
    component: MonitoringDetailMatkulUTSView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uts/pencairan",
    component: TabelPencairanUTSView,
    meta: {
      requiresAuth: true,
    },
  },
  // UTS Susulan
  {
    path: "/penilaian/uts-susulan",
    component: DaftarMatkulUTSSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uts-susulan/detail",
    component: DetailMatkulUTSSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uts-susulan/monitoring",
    component: MonitoringDaftarMatkulUTSSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uts-susulan/monitoring-detail",
    component: MonitoringDetailMatkulUTSSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uts-susulan/pencairan",
    component: TabelPencairanUTSSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  // UAS
  {
    path: "/penilaian/uas",
    component: DaftarMatkulUASView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uas/detail",
    component: DetailMatkulUASView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uas/monitoring",
    component: MonitoringDaftarMatkulUASView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uas/monitoring-detail",
    component: MonitoringDetailMatkulUASView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uas/pencairan",
    component: TabelPencairanUASView,
    meta: {
      requiresAuth: true,
    },
  },
  // UAS Susulan
  {
    path: "/penilaian/uas-susulan",
    component: DaftarMatkulUASSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uas-susulan/detail",
    component: DetailMatkulUASSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uas-susulan/monitoring",
    component: MonitoringDaftarMatkulUASSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uas-susulan/monitoring-detail",
    component: MonitoringDetailMatkulUASSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nilai-uts",
    component: UTSMahasiswaView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nilai-uas",
    component: UASMahasiswaView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/uas-susulan/pencairan",
    component: TabelPencairanUASSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  // END Penilaian Hasil Belajar Baru
  {
    path: "/",
    component: DasborView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/hotline",
    component: HotlineView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jadwal-kuliah",
    component: JadwalKuliahView,
    meta: {
      requiresAuth: true,
    },
  },
  // Penilaian Ujian

  {
    path: "/penilaian-uts",
    component: UtsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian-uts/detail",
    component: DetailUtsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian-uts-susulan",
    component: UtsSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian-uts-susulan/detail",
    component: DetailUtsSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian-uas",
    component: UasView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian-uas/detail",
    component: DetailUasView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/monitoring",
    component: MonitoringPenilaianView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian-uts-susulan/monitoring",
    component: MonitoringPenilaianUTSSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian-uts-susulan/monitoring/detail",
    component: DetailMonitoringUtsSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/monitoring-pencairan",
    component: MonitoringPencairanUTSView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/monitoring-pencairan-uts-susulan",
    component: MonitoringPencairanUTSSusulanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian-uts/monitoring/detail",
    component: DetailMonitoringPenilaianUTS,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/monitoring-uas",
    component: MonitoringPenilaianAkhirView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian-uas/monitoring/detail",
    component: DetailMonitoringPenilaianAkhir,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/konfigurasi/jadwal",
    component: KonfigurasiJadwalView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penilaian/konfigurasi/nilai",
    component: KonfigurasiNilaiView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kontrak-perkuliahan",
    component: KontrakPerkuliahanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kontrak-perkuliahan/monitoring",
    component: MonitoringKontrakPerkuliahanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kontrak-perkuliahan/detail/:id",
    component: DetailKontrakPerkuliahanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jadwal-kuliah-absen",
    component: AbsenView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jadwal-uts",
    component: JadwalUtsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jadwal-uas",
    component: JadwalUasView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/jurusan",
    component: JurusanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/keuangan",
    component: KeuanganView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/lembaga",
    component: LembagaView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nilai-khs",
    component: KhsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/nilai-transkrip",
    component: TranskripView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penelitian",
    component: PenelitianAdmin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penelitian-usul",
    component: PenelitianView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penelitian-validasi",
    component: PenelitianValidasi,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penelitian-reviewer",
    component: PenelitianReviewer,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/penelitian-pencairan",
    component: PenelitianPencairan,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pengumuman",
    component: PengumumanView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/perwalian",
    component: PerwalianView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profil",
    component: ProfilView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/program-studi",
    component: ProgramStudiView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/program-studi/:id",
    component: ProgramStudiDetail,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pusat-kajian",
    component: PusatKajianView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "auth.login",
    component: AuthLoginView,
  },
  {
    path: "/user-admin",
    component: UserAdminView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user-dosen",
    component: UserDosenView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user-mahasiswa",
    component: userMahasiswaView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pengajuan-ijazah",
    component: PengajuanIjazah,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/verifikasi-ijazah",
    component: VerifikasiIjazahBAAK,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/arsip-ijazah",
    component: ArsipIjazah,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:notFound(.*)",
    redirect: (to) => {
      return {
        path: "/",
      };
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "here",
  linkExactActiveClass: "active",
});

router.beforeEach((to, from) => {
  if (to.meta.requiresAuth && !loggedIn.value) {
    return {
      name: "auth.login",
      query: {
        redirect: to.fullPath,
      },
    };
  }
});

export default router;
