<template>
    
    <container-app>
        <template #content>

            <!-- filter semester -->
            <div class="d-flex align-items-center gap-2 mb-5 ms-5">
                <label class="form-label m-0">Semester</label>
                <select class="form-select w-auto" v-model="jadwal_id" @change="indexPengusulanDana">
                    <option 
                        v-for="jadwal in data_jadwal_pengusulan" 
                        :key="jadwal.id" 
                        :value="jadwal.id">
                        {{jadwal.semester}}
                    </option>
                </select>
            </div>
            
            <card-basic>
                <template #content>

                    <table-basic>
                        <template #thead>
                            <tr class="fw-bolder bg-light fs-7">
                                <th class="rounded-start ps-5 w-md-50px">No.</th>
                                <th class="w-md-150px">Dosen</th>
                                <th>Usulan PPM</th>
                                <th class="rounded-end pe-5 w-md-200px">Aksi</th>
                            </tr>
                        </template>

                        <template #tbody>
                            <tr v-for="(pengusulan_dana, i) in data_pengusulan_dana" :key="i">
                                <td class="text-end">{{ i+1 }}</td>
                                <td>
                                    <span class="fw-bold d-block">{{ pengusulan_dana.nama }}</span>
                                    NIDN. {{ pengusulan_dana.nidn }}
                                </td>
                                <td>
                                    <div v-for="(dana, i) in pengusulan_dana.data_usulan" :key="i" :class="i > 0 ? 'mt-6' : ''">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <span>Usulan {{ i+1 }}</span>
                                                <span class="badge badge-info ms-3">{{ dana.jenis }}</span>
                                            </div>
                                            <span class="badge ms-3" :class="dana.keterangan == 'DISETUJUI' ? 'badge-success' : 'badge-secondary'">{{ dana.keterangan }}</span>
                                        </div>
                                        <p class="fw-bold mb-1">{{ dana.judul }}</p>
                                        <span>Usulan pencairan tahap {{ dana.tahap }}, Rp.{{ dana.biaya }}</span>
                                    </div>
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-secondary" data-bs-toggle="modal" data-bs-target="#modalDetailUsulanPencairan" @click="detailUsulanPencairan(i)">Detail</button>
                                </td>
                            </tr>
                        </template>
                    </table-basic>

                </template>
            </card-basic>

        </template>
    </container-app>

    <!-- begin::modal -->
    <modal-medium id="modalDetailUsulanPencairan" title="Detail Usulan Pencairan">
        <template #content>
            
            <div class="card border border-secondary">
                <div class="card-basic p-4">
                    <label-basic name="Profil Dosen" />

                    <div class="row mb-2 mt-3">
                        <div class="col-sm-3">Nama Dosen</div>
                        <div class="col-sm-9 fw-bold d-block">: {{ pengusul.nama }}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-3">NIDN</div>
                        <div class="col-sm-9 fw-bold d-block">: {{ pengusul.nidn }}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">Program Studi</div>
                        <div class="col-sm-9 fw-bold d-block">: {{ pengusul.program_studi }}</div>
                    </div>
                </div>
            </div>

            <nav class="mt-4">
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link" :class="i == 1 ? 'active' : ''" id="nav-detail_usulan_ppm_usulan_1-tab" data-bs-toggle="tab" type="button" role="tab" aria-selected="true" v-for="i in jumlah_usulan" :key="i" @click="detailUsulanPencairan(index_usulan, i-1)">Usulan {{ i }}</button>
                </div>
            </nav>

            <div class="card border border-secondary">
                <div class="card-basic p-4">
                    <label-basic name="Usulan Pencairan Dana" />

                    <div class="row mb-2 mt-3">
                        <div class="col-sm-3">Jenis</div>
                        <div class="col-sm-9 fw-bold d-block">: {{ pengusulan_dana.jenis }}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-3">Judul</div>
                        <div class="col-sm-9 fw-bold d-block">: {{ pengusulan_dana.judul }}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-3">Rencana Luaran</div>
                        <div class="col-sm-9 fw-bold d-block">: {{ pengusulan_dana.target_iuran }}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-3">Dana Internal</div>
                        <div class="col-sm-9 fw-bold d-block">: Rp. {{ pengusulan_dana.dana }}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-3">Usulan Dana Tahap {{ pengusulan_dana.tahap }}</div>
                        <div class="col-sm-9 fw-bold d-block">: Rp. {{ pengusulan_dana.biaya }}</div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-3">File LOA</div>
                        <div class="col-sm-9 fw-bold d-block">: <a :href="pengusulan_dana.link_file_loa" v-if="pengusulan_dana.link_file_loa != '-'" target="_blank">Lihat File</a></div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-3">File Artikel</div>
                        <div class="col-sm-9 fw-bold d-block">: <a :href="pengusulan_dana.link_file_artikel" v-if="pengusulan_dana.link_file_artikel != '-'" target="_blank">Lihat File</a></div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-3">URL Artikel</div>
                        <div class="col-sm-9 fw-bold d-block">: <a :href="pengusulan_dana.url_artikel" v-if="pengusulan_dana.url_artikel != '-'" target="_blank">Kunjungi URL</a></div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-3">Status</div>
                        <div class="col-sm-9 fw-bold d-block">: <span class="badge" :class="pengusulan_dana.keterangan == 'DISETUJUI' ? 'badge-success' : 'badge-secondary'">{{ pengusulan_dana.keterangan }}</span></div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-3">Validasi</div>
                        <div class="col-sm-9">: 
                            <button class="btn btn-sm btn-success" v-if="pengusulan_dana.validasi_staf_lppm == 0 & lembaga_jabatan == 'LPPM STAF'" @click="validasiPengusulanDana(pengusulan_dana.id, 'STAF_LPPM')">Validasi</button>
                            <button class="btn btn-sm btn-success" v-if="pengusulan_dana.validasi_lppm == 0 & lembaga_jabatan == 'LPPM KETUA'" @click="validasiPengusulanDana(pengusulan_dana.id, 'LPPM')">Validasi</button>
                            <button class="btn btn-sm btn-success" v-if="pengusulan_dana.validasi_rektor == 0 & lembaga_jabatan == 'ITG REKTOR'" @click="validasiPengusulanDana(pengusulan_dana.id, 'REKTOR')">Validasi</button>
                        </div>
                    </div>
                    <div class="row mb-2" v-if="lembaga_jabatan == 'BAK KEPALA'">
                        <div class="col-sm-3">Tanggal Pencairan</div>
                        <div class="col-sm-9 d-flex justify-content-between">
                            <span v-if="!detail[0]">: {{ pengusulan_dana.tanggal }}</span>
                            <span v-if="!detail[0]">
                                <span class="btn btn-sm btn-link p-0" @click="detail[0] = !detail[0]">Ubah</span>
                            </span>
                            <form v-if="detail[0]" @submit.prevent="updateTanggalPengusulanDana" class="d-flex">
                                <input type="date" class="form-control form-control-sm" required v-model="form_pengusulan_dana.tanggal">
                                <input type="submit" value="Simpan" class="btn btn-sm btn-success ms-2">
                            </form>
                        </div>
                    </div>
                    <div class="row" v-if="pengusulan_dana.validasi_rektor == 1 & pengusulan_dana.tanggal != null & !detail[0] & lembaga_jabatan != 'ITG REKTOR'">
                        <div class="col-sm-3">Bukti Pencairan</div>
                        <div class="col-sm-9">
                            <a :href="pengusulan_dana.link_pdf" target="_blank" class="btn btn-sm btn-danger ms-2">Download</a>
                        </div>
                    </div>
                </div>
            </div>

        </template>
    </modal-medium>
    <!-- end::modal -->
</template>

<script>

import {indexPengusulanDana, validasiPengusulanDana, showPengusulanDana, updateTanggalPengusulanDana} from '@/apis/pengusulanDana';
import {lembagaJabatan} from '@/apis/user';
import {indexJadwalPengusulan} from '@/apis/jadwalPengusulan';

export default {
    beforeMount() {
        localStorage.setItem('menu', 'Penelitian');
        localStorage.setItem('halaman', 'Pencairan');
    },
    data() {
        return {
            lembaga_jabatan: {},
            data_pengusulan_dana: {},
            pengusul: {},
            pengusulan_dana: {},
            form_pengusulan_dana: {},
            index_usulan: 0,
            jumlah_usulan: 0,
            detail: [false],
            jadwal_id: 0,
            data_jadwal_pengusulan: [],
        }
    },
    mounted() {
        this.indexJadwalPengusulan();
        this.lembagaJabatan();
        this.indexPengusulanDana();
    },
    methods: {
        async indexJadwalPengusulan() {
            const res = await indexJadwalPengusulan();

            this.data_jadwal_pengusulan = res.data.data;
        },
        async lembagaJabatan() {
            const res = await lembagaJabatan();

            this.lembaga_jabatan = res.data.lembaga_jabatan;
        },
        async indexPengusulanDana() {
            const res = await indexPengusulanDana(this.jadwal_id);

            this.data_pengusulan_dana = res.data.data;
            this.jadwal_id = res.data.jadwal_id;
        },
        detailUsulanPencairan(index, usulan = 0) {
            this.index_usulan = index;
            this.pengusul = this.data_pengusulan_dana[index];
            this.jumlah_usulan = this.pengusul.jumlah_usulan;

            this.showPengusulanDana( this.pengusul.data_usulan[usulan].id );
        },
        async validasiPengusulanDana(id, validasi) {
            const res = await validasiPengusulanDana({
                'id' : id,
                'validasi' : validasi
            });

            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Usulan pencairan berhasil divalidasi',
                    showConfirmButton: false,
                    timer: 3000
                });

                this.indexPengusulanDana();
                this.showPengusulanDana(id);
            }
        },
        async showPengusulanDana(id) {
            this.pengusulan_dana = {};
            this.form_pengusulan_dana = {};
            const res = await showPengusulanDana(id);

            this.pengusulan_dana = res.data.data;
            this.form_pengusulan_dana = this.pengusulan_dana;
        },
        async updateTanggalPengusulanDana() {
            const res = await updateTanggalPengusulanDana(this.form_pengusulan_dana);

            if( res.status == 200 ) {
                Swal.fire({
                    icon: 'success',
                    text: 'Tanggal pencairan berhasil diperbarui',
                    showConfirmButton: false,
                    timer: 3000
                });

                this.detail[0] = false;

                this.showPengusulanDana(this.pengusulan_dana.id);
                this.indexPengusulanDana();
            }
        }
    }
}
</script>