<template>
  <menu-basic nama="Perwalian" url="/perwalian">
    <template #icon>
      <icon-bill />
    </template>
  </menu-basic>

  <menu-dropdown
    nama="Kontrak Perkuliahan"
    label="/kontrak-perkuliahan"
    v-if="showMenu('KONTRAK_PERKULIAHAN')"
  >
    <template #icon>
      <icon-file />
    </template>
    <template #items>
      <menu-item nama="Kontrak Perkuliahan" url="/kontrak-perkuliahan" />
      <menu-item nama="Monitoring" url="/kontrak-perkuliahan/monitoring" />
    </template>
  </menu-dropdown>

  <menu-basic v-else nama="Kontrak Perkuliahan" url="/kontrak-perkuliahan">
    <template #icon>
      <icon-contract />
    </template>
  </menu-basic>

  <menu-label nama="Penilaian Hasil Belajar" />
  <menu-basic nama="UTS" url="/penilaian/uts">
    <template #icon>
      <icon-uts />
    </template>
  </menu-basic>
  <menu-basic nama="UTS Susulan" url="/penilaian/uts-susulan">
    <template #icon>
      <icon-uts-2 />
    </template>
  </menu-basic>
  <menu-basic nama="UAS" url="/penilaian/uas">
    <template #icon>
      <icon-uas />
    </template>
  </menu-basic>
  <menu-basic nama="UAS Susulan" url="/penilaian/uas-susulan">
    <template #icon>
      <icon-uas-2 />
    </template>
  </menu-basic>

  <menu-label nama="PKM" />
  <menu-dropdown nama="Penelitian dan PkM" label="/penelitian">
    <template #icon>
      <icon-flower />
    </template>

    <template #items>
      <menu-item
        nama="Validasi PPM"
        url="/penelitian"
        :class="!showMenu('PENELITIAN_ADMIN') ? 'd-none' : ''"
      />
      <menu-item nama="Pengusulan" url="/penelitian-usul" />
      <menu-item
        nama="Reviewer"
        url="/penelitian-reviewer"
        :class="!showMenu('PENELITIAN_REVIEWER') ? 'd-none' : ''"
      />
      <menu-item
        nama="Pencairan"
        url="/penelitian-pencairan"
        :class="!showMenu('PENELITIAN_PENCAIRAN') ? 'd-none' : ''"
      />
    </template>
  </menu-dropdown>
  <menu-label />
</template>

<script>
// store
import { getUserData } from "@/store";

export default {
  data() {
    return {
      user: getUserData(),
    };
  },
  methods: {
    showMenu(akses) {
      if (this.user.akses != null) {
        if (!this.user.akses.includes("ALL")) {
          return this.user.akses.includes(akses);
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
