<template>
    <menu-basic nama="Dasbor" url="/">
        <template #icon>
            <icon-app />
        </template>
    </menu-basic>

    <menu-label nama="Menu" />
    <peran-admin v-if="this.user.peran == 'ADMIN'" />
    <peran-dosen v-if="this.user.peran == 'DOSEN'" />
    <peran-mahasiswa v-else-if="this.user.peran == 'MAHASISWA'" />

    <menu-basic nama="Pengumuman" url="/pengumuman">
        <template #icon>
            <icon-alert />
        </template>
    </menu-basic>
</template>

<script>

// components / peran
import PeranAdmin from '@/components/_peran/Admin.vue';
import PeranDosen from '@/components/_peran/Dosen.vue';
import PeranMahasiswa from '@/components/_peran/Mahasiswa.vue';

// store
import { getUserData } from '@/store';

export default {
    components: {
        PeranAdmin,
        PeranDosen,
        PeranMahasiswa
    },
    data() {
        return {
            user : {
                peran : '-'
            }
        }
    },
    mounted() {
        this.user.peran = getUserData().peran;
    }
}

</script>